.start-container {
  display: flex;
  flex-direction: column;
}

.test-container {
  display: flex;
  flex-direction: column;

  .test-head {
    display: flex;
    justify-content: center;

    .resolved-container {
      display: flex;

      .resolved-label {
        padding: 0 10px;
      }

      .resolved-value {
        padding: 0 10px;
      }
    }

    .err-container {
      display: flex;

      .err-label {
        // width: 160px;
        padding: 0 10px;
      }

      .err-value {
        padding: 0 10px;
      }
    }
  }

  .test-body {
    display: flex;
    flex-direction: column;
    padding: 20px;

    .test-question-container {
      flex: 1 1 auto;
      min-height: 20vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .test-answer-container {
      flex: 1 1 auto;
      min-height: 20vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .test-btns-container {
    display: flex;
    justify-content: center;
    margin: 10px 5px;
  }

  .feedback > .btn {
    margin: 0 10px;
  }
}

.select-container {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.simple-container {
  flex: 1 1 auto;
  height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttons {
  display: flex;
  justify-content: center;
  padding-top: 5px;
}

.email {
  font-size: 10px;
  padding: 20px;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
